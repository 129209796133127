<template>
  <DataTable :value="data" class="p-datatable-responsive">
    <Column
      v-for="{ field, header } in columns"
      :key="field"
      :field="field"
      :header="header"
    >
      <template #body="slotProps">
        <span class="p-column-title">{{ header }}</span>
        <span>{{ slotProps.data[field] }}</span>
      </template>
    </Column>
    <Column :exportable="false">
      <template #body="slotProps">
        <div class="p-d-flex p-jc-start">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-text p-button-sm"
            @click="$emit('update', slotProps.data.id)"
          />
          <Button
            v-if="!slotProps.data.active"
            icon="pi pi-check"
            class="p-button-rounded p-button-success p-button-text p-button-sm"
            @click="$emit('enable', slotProps.data.id)"
          />
          <Button
            v-else
            icon="pi pi-minus-circle"
            class="p-button-rounded p-button-danger p-button-text p-button-sm"
            @click="$emit('delete', slotProps.data.id)"
          />
        </div>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'

export default {
  components: { DataTable, Column, Button },
  emits: ['update', 'enable', 'delete'],
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    columns: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/table.scss';
.p-datatable {
  ::v-deep(th:last-child) {
    width: 90px;
  }
  ::v-deep(.col-center) {
    text-align: center;
  }
}
</style>
