<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <div class="p-d-flex p-ai-center">
    <div class="p-mr-1"><h1>Locations</h1></div>
    <div>
      <Button
        label="Create"
        icon="pi pi-plus"
        class="p-button-rounded p-button-text p-button-sm"
        @click="$router.push({ name: 'LocationCreate' })"
      />
    </div>
  </div>

  <BaseTable
    v-if="$store.getters.isSuper"
    :data="locations"
    :columns="columns"
    @update="goToUpdate"
    @delete="deleteDocument"
    @enable="enableDocument"
  />
  <ConfirmDialog
    :display="displayConfirmDelete"
    :disable="disableConfirmButton"
    @confirm="confirm"
    @display="display"
    :message="'Are you sure you want to disable?'"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import BaseTable from '@/components/BaseTable.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { useDeleteDocument } from '@/composables/useDeleteDocument.ts'
import Button from 'primevue/button.js'
import { useToast } from 'primevue/usetoast.js'
import { db } from '@/services/firebase.ts'
import { useStore } from 'vuex'
import { Location } from '@/types'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default defineComponent({
  components: { BaseTable, ConfirmDialog, Button, BaseBreadcrumb },
  setup() {
    const locations = ref<Array<Location>>([])
    const toast = useToast()
    const router = useRouter()
    const store = useStore()
    const {
      disableConfirmButton,
      displayConfirmDelete,
      deleteDocument,
      documentId
    } = useDeleteDocument()
    const columns = ref([
      { field: 'name', header: 'Name' },
      { field: 'active', header: 'Active' }
    ])
    const breadcrumbs = [{ label: 'Locations' }]

    function getLocations() {
      db.ref('locations')
        .once('value')
        .then(data => {
          const locationList: Array<Location> = []
          data.forEach(function (snapshot) {
            const row: Location = { ...snapshot.val(), id: snapshot.key }
            locationList.push(row)
          })
          locations.value = locationList
        })
    }

    function updateDocument(params: Record<string, boolean>, msg: string) {
      db.ref('locations/' + documentId.value)
        .update(params)
        .then(() => {
          getLocations()
          displayConfirmDelete.value = false
          disableConfirmButton.value = false
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: msg,
            life: 3000
          })
        })
        .catch(error => {
          toast.add({
            severity: 'error',
            summary: 'Error Message',
            detail: error.message,
            life: 3000
          })
        })
    }

    function confirm() {
      disableConfirmButton.value = true
      updateDocument({ active: false }, 'Location successfully disabled')
    }

    function display(value: boolean) {
      displayConfirmDelete.value = value
    }

    function goToUpdate(id: string) {
      router.push({
        name: 'LocationUpdate',
        params: { id }
      })
    }

    function enableDocument(id: string) {
      documentId.value = id
      updateDocument({ active: true }, 'Location successfully enabled')
    }

    getLocations()

    return {
      locations,
      columns,
      displayConfirmDelete,
      disableConfirmButton,
      deleteDocument,
      confirm,
      display,
      goToUpdate,
      enableDocument,
      store,
      breadcrumbs
    }
  }
})
</script>
